.main_container {
  min-height: 100vh;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background: rgb(203, 108, 230);
  background: linear-gradient(
    0deg,
    rgba(203, 108, 230, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.logo {
  max-width: 300px;
  max-height: 300px;
  margin-top: 20px;
  margin-bottom: 40px;
}
