$phone: 460px;
$tablet: 768px;
$desktop: 1024px;

.main_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: $phone) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  // @media screen and (min-width: $desktop) {
  //   flex-direction: row;
  //   justify-content: space-around;
  //   align-items: center;
  //   flex-wrap: wrap;
  // }

  img {
    width: 150px;
    height: 150px;
    margin: 15px;
  }
}
